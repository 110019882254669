import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, NavItem, Nav, Button, Row, Col,
  Form, FormControl,
  InputGroup,
  DropdownButton,
  Dropdown,
  MenuItem
} from 'react-bootstrap';
import ReactLoading from 'react-loading';


export default class UserWalletModal extends Component {

  render() {
    return (
      <Modal bsSize='small' show={this.props.show} animation={false}>
        <div style={{
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          margin: 'auto auto',
          display: 'flex',
        }}>
          <div style={{
            display: 'inline-block',
          }}>
              <ReactLoading type={'balls'} color={'blue'} />
              <div className="mb-3">
                Loading ...
              </div>
          </div>
        </div>
      </Modal>
    );
  }
}
