import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/bs4-utilities.min.css';
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0';
import './assets/css/demo.css';
import './assets/css/custom.css';
import './assets/css/pe-icon-7-stroke.css';
import AdminLayout from 'layouts/Admin.jsx';
import { Provider } from 'react-redux';
import store from './redux/store';
import getAuth from './helpers/getAuth';
import Login from './views/Login';
import axios from 'axios';
axios.defaults.headers.common['version'] = '20201130';

axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  console.log('Middleware ',error.response);
  if(error && error.response && error.response.status === 401){
    localStorage.clear();
    window.location.reload();
    return;
  }
  return Promise.reject(error);
});

getAuth(store);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ToastContainer />
      <Switch>
        <Route exact path='/admin/login' component={Login} />
        <Route path='/admin' render={props => <AdminLayout {...props} />} />
        <Redirect from='/' to='/admin/dashboard' />
      </Switch>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
