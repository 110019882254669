import axios from 'axios';
import { setAuthToken } from '../../helpers/config';
import { PROFILE_LOADING, SET_CURRENT_USER, GET_RATES, CHANGE_PASSWORD } from '../constants';
import { toast } from 'react-toastify';

export const login = ({ email, password }) => dispatch => {
 return axios
    .post(`/api/auth/admin/login`, { email, password })
    .then(res => {
      const account = res.data.data;
      if (account.role !== 'admin') return toast.warn('Admin Only!!!!!!!');
      localStorage.setItem('auth', account.access_token);
      localStorage.setItem('auth-expired-at', account.expiredAt);
      setAuthToken(account.access_token);
      dispatch(setCurrentUser(account.user));
    })
    .catch(err => {
      console.log(err, err.response);
      toast.error('Authentication Error!!');
    });
};

export const changePassword = data => dispatch => {
  return axios
    .post('/api/auth/change-password', { password: data.old_password, new_password: data.password })
    .then(res => {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: res.data.data
      });
      toast.info('Update successfully!');
      return true;
    })
    .catch(err => toast.error('Your old password is incorrect'));
};

export const getCurrentUser = dispatch => {
  dispatch({ type: PROFILE_LOADING });
  return axios.get('/api/user/get-my-info').then(res => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.data
    });
  });
};

export const setCurrentUser = auth => {
  return {
    type: SET_CURRENT_USER,
    payload: auth
  };
};

export const logout = () => dispatch => {
  localStorage.removeItem('auth');
  setAuthToken(false);
  dispatch(setCurrentUser(null));
};
