import axios from "axios";
import mergeObject from "../merge-object";

export default {
  getTransactions({
    limit,
    page,
    sort = "-createdAt",
    type,
    search_text,
  } = {}) {
    return axios.get("/api/wallet/admin/get-transactions", {
      params: mergeObject({ limit, page, type, search_text, sort }),
    });
  },

  getSetting() {
    return axios.get("/api/config/admin");
  },

  updateSetting(data) {
    return axios.post("/api/config/admin", data);
  },

  getWalletById(id) {
    return axios.get("/api/wallet/admin/get-wallets-by-user-id?id=" + id);
  },

  getPackages() {
    return axios.get("/api/investment/get-packages");
  },

  investForUser(userId, packageId, walletId) {
    return axios.post("/api/investment/admin/invest-by-id?id=" + userId, {
      packageId,
      walletId,
    });
  },

  depositForUser(userId, amount, walletId) {
    return axios.post("/api/wallet/admin/deposit-for-user?id=" + userId, {
      amount,
      walletId,
    });
  },

  getAllInvestments({ page, limit, search_text } = { page: 1, limit: 10 }) {
    return axios.get(`/api/investment/admin/all`, {
      params: mergeObject({ limit, page, search_text }),
    });
  },

  resetSendState(){
    const api = '/api/wallet/admin/reset-send-lock-state';
    return axios.get(api);
  },

  getSendState(){
    const api = '/api/wallet/admin/get-send-lock-state';
    return axios.get(api);
  }
};
