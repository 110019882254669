import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import AdminNavbarLinks from '../Navbars/AdminNavbarLinks.jsx';
import logo from 'assets/img/logo.png';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname === routeName ? 'active' : '';
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: 'url(' + this.props.image + ')'
    };
    return (
      <div id='sidebar' className='sidebar' data-color={this.props.color} data-image={this.props.image} style={{overflowY: "scroll", ...sidebarBackground}}>
        <div style={{background: 'rgba(0,0,0,0.7)'}}>
        <div className='logo'>
          <Link to='/' className='simple-text logo-normal'>
            <img src={logo} alt='logo_image' className='rounded' style={{ width: 120 }} />
          </Link>
        </div>
        <div className='sidebar-wrapper'>
          <ul className='nav'>
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.props.routes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li className={prop.upgrade ? 'active active-pro' : this.activeRoute(prop.layout + prop.path)} key={key}>
                    <NavLink to={prop.layout + prop.path} className='nav-link' activeClassName='active'>
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
