import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Table,
  InputGroup,
  FormControl,
  Form,
  Button,
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { user } from "../helpers/api";
import Moment from "../components/Commons/Moment";
import classnames from "classnames";
import UserDetailModal from "../components/Modals/UserDetailModal";
import ReactPaginate from "react-paginate";
import USD from "../components/Commons/USD";
import { connect } from "react-redux";
import utils from "helpers/utils";
import { toast } from "react-toastify";

class Users extends Component {
  state = {
    users: [],
    show: false,
    currentAccount: null,
    search_text: "",
    page: 1,
    pages: 1,
    isLoading: true,
  };

  componentDidMount() {
    this.getUsers(1);
  }

  getUsers = (page)=>{
    if(!this.state.isLoading){
      this.setState({isLoading: true});
    }
    user
      .getAccounts({ search_text: this.state.search_text, limit: 15, page })
      .then((res) =>
        this.setState({ users: res.data.data, pages: res.data.total_page, page, isLoading: false })
      );
  }


  search = (event) => {
    event.preventDefault();
   this.getUsers(1);
  }

  clearSearch = (event) => {
    event.preventDefault();
    this.setState({page: 1, search_text: ''},()=>{
      this.getUsers(1);
    });
  }

  onPageChange = (data) => {
    const page = data.selected + 1;
    this.setState({ page }, () => {
      this.getUsers(page);
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (user, total) => {
    this.setState({ show: true, currentAccount: { ...user, total } });
  };

  onChangeUserState = (accountId, state) => {
    user.updateUserState(accountId, state).then((success) => {
      const index = this.state.users.findIndex((el) => el._id === accountId);
      const newData = [...this.state.users];
      if (index >= 0) {
        Object.assign(newData[index], { status: state });
        this.setState({ users: newData });
      }
      if (success) {
        toast.success("Update status successfully");
      } else {
        toast.error("Update status failure");
      }
    });
  };

  render() {
    const { users, show, currentAccount, pages, page } = this.state;
    const { rates } = this.props;
    return (
      <div className="content">
        <UserDetailModal
          account={currentAccount}
          show={show}
          handleClose={this.handleClose}
        />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Users"
                extra={
                  <Form inline className="ml-5">
                    <InputGroup>
                      <FormControl
                        placeholder="Email..."
                        aria-label="Email..."
                        value={this.state.search_text}
                        onChange={event=>this.setState({search_text: event.target.value})}
                        aria-describedby="basic-addon2"
                      />
                    </InputGroup>
                    <Button type="submit" onClick={this.search}>Search</Button>
                    <Button type="submit" onClick={this.clearSearch}>Clear</Button>
                  </Form>
                }
                ctTableFullWidth
                ctTableResponsive
                content={
                  this.state.isLoading?
                  <div className="row mx-0 py-5 text-center">
                    <span>Loading ...</span>
                  </div>
                  :<Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Email</th>
                        <th>Total Investment</th>
                        <th>Status</th>
                        <th>Detail</th>
                        <th>Change Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((account, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Moment date={account.createdAt} />
                            </td>
                            <td
                              className={classnames({
                                "text-success": account.status == "active",
                                "text-warning": account.status == "pending",
                                "text-warning":
                                  account.status == "blocked" ||
                                  account.status == "disabled",
                              })}
                            >
                              {account.email}
                              {account.status == "active" ? (
                                <i className="fa fa-check-circle" />
                              ) : (
                                <i className="fa fa-exclamation-circle" />
                              )}
                            </td>
                            <td>
                              {utils.formatSeparator(
                                account.user.total_investment
                              )}
                            </td>
                            <td>{utils.toUpperFirstLetter(account.status)}</td>
                            <td>
                              <span
                                className="text-info cursor-pointer"
                                onClick={() => this.handleShow(account)}
                              >
                                Detail
                              </span>
                            </td>
                            <td>
                              <span
                                className={` ${
                                  account.status !== "active"
                                    ? "text-info"
                                    : "text-danger"
                                } cursor-pointer`}
                                onClick={() =>
                                  this.onChangeUserState(
                                    account._id,
                                    account.status === "active"
                                      ? "disabled"
                                      : "active"
                                  )
                                }
                              >
                                {account.status === "active"
                                  ? "Block User"
                                  : "Active User"}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="page-link rounded-circle"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.onPageChange}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link rounded-circle"
                previousClassName="page-item"
                previousLinkClassName="page-link rounded-circle"
                nextClassName="page-item "
                nextLinkClassName="page-link rounded-circle"
                activeClassName="active"
                forcePage={+page - 1}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rates: state.auth.rates,
});

export default connect(mapStateToProps)(Users);
