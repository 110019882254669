import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import { Table } from 'react-bootstrap';
import { wallet } from '../../helpers/api';
import ReactPaginate from 'react-paginate';
import USD from '../Commons/USD';
import { connect } from 'react-redux';
import classnames from 'classnames';

class WaletTable extends Component {
  static propTypes = {
    wallet: PropTypes.string,
    type: PropTypes.string,
    user_id: PropTypes.string,
    date: PropTypes.string
  };
  state = {
    list: null,
    page: 1,
    pages: 1
  };
  componentDidMount() {
    this.props.type === 'deposit' &&
      wallet.getDeposits({ dst_wallet: this.props.wallet, user_id: this.props.user_id, date: this.props.date, limit: 10, page: 1 }).then(res => {
        this.setState({
          list: res.data.data,
          pages: res.data.total_page,
          type: this.props.type
        });
      });
    this.props.type === 'withdraw' &&
      wallet.getWithdraw({ src_wallet: this.props.wallet, user_id: this.props.user_id, date: this.props.date, limit: 10, page: 1 }).then(res => {
        this.setState({
          list: res.data.data,
          pages: res.data.total_page,
          type: this.props.type
        });
      });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      nextProps.type === 'deposit' &&
        wallet.getDeposits({ dst_wallet: nextProps.wallet, user_id: nextProps.user_id, date: nextProps.date, limit: 10, page: 1 }).then(res => {
          this.setState({
            list: res.data.data,
            pages: res.data.total_page,
            page: 1,
            type: nextProps.type
          });
        });
      nextProps.type === 'withdraw' &&
        wallet.getWithdraw({ src_wallet: nextProps.wallet, user_id: nextProps.user_id, date: nextProps.date, limit: 10, page: 1 }).then(res => {
          this.setState({
            list: res.data.data,
            pages: res.data.total_page,
            page: 1,
            type: nextProps.type
          });
        });
    }
  }
  onPageChange = data => {
    let page = data.selected + 1;
    this.setState({ page }, () => {
      this.props.type === 'deposit' &&
        wallet.getDeposits({ dst_wallet: this.props.wallet, user_id: this.props.user_id, date: this.props.date, limit: 10, page }).then(res => {
          this.setState({
            list: res.data.data,
            pages: res.data.total_page,
            type: this.props.type
          });
        });
      this.props.type === 'withdraw' &&
        wallet.getWithdraw({ src_wallet: this.props.wallet, user_id: this.props.user_id, date: this.props.date, limit: 10, page }).then(res => {
          this.setState({
            list: res.data.data,
            pages: res.data.total_page,
            type: this.props.type
          });
        });
    });
  };
  render() {
    const { list, page, pages, type } = this.state;
    const { rates } = this.props;
    return (
      <div>
        <Card
          ctTableFullWidth
          ctTableResponsive
          content={
            type === 'deposit' ? (
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Wallet</th>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>TxHash</th>
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.user.email}</td>
                          <td>{item.dst_wallet}</td>
                          <td>{item.dst_wallet_type}</td>
                          <td>{<USD value={item.amount / +rates[item.dst_wallet]} />}</td>
                          <td>{item.amount}</td>
                          <td className='text-truncate' style={{ maxWidth: '20rem' }} title={item.hash}>
                            <a target='_blank' rel='noopener noreferrer' href={'https://etherscan.io/tx/' + item.hash}>
                              {item.hash}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : type === 'withdraw' ? (
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Wallet</th>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Fee</th>
                    <th>{'Status'}</th>
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.user.email}</td>
                          <td className='text-truncate' style={{ maxWidth: '20rem' }} title={item.dst_address}>
                            <a target='_blank' rel='noopener noreferrer' href={'https://etherscan.io/address/' + item.dst_address}>
                              {item.dst_address}
                            </a>
                          </td>
                          <td>{item.src_wallet}</td>
                          <td>{item.src_wallet_type}</td>
                          <td>{<USD value={item.amount / +rates[item.src_wallet]} />}</td>
                          <td>{item.amount}</td>
                          <td>{item.fee}</td>
                          <td
                            className={classnames({
                              'text-success': item.status === 'approved',
                              'text-danger': item.status === 'rejected',
                              'text-warning': item.status === 'pending'
                            })}
                          >
                            {item.status}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <></>
            )
          }
        />
        {pages > 1 && (
          <ReactPaginate
            previousLabel='<'
            nextLabel='>'
            breakLabel='...'
            breakClassName='page-link rounded-circle'
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.onPageChange}
            containerClassName='pagination'
            pageClassName='page-item'
            pageLinkClassName='page-link rounded-circle'
            previousClassName='page-item'
            previousLinkClassName='page-link rounded-circle'
            nextClassName='page-item '
            nextLinkClassName='page-link rounded-circle'
            activeClassName='active'
            forcePage={+page - 1}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rates: state.auth.rates
});

export default connect(mapStateToProps)(WaletTable);
