import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import { changePassword } from '../redux/auth/auth.action';
import { connect } from 'react-redux';
import { isLength, equals } from 'validator';
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: '',
      password: '',
      verify_password: ''
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.changePassword(this.state).then(success => {
      if (success) {
        this.setState({
          old_password: '',
          password: '',
          verify_password: ''
        })
      }
    });
  }

  render() {
    const { old_password, password, verify_password } = this.state;
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={6} smOffset={3} sm={12} className='margin-top'>
              <Card
                title='Change password'
                content={
                  <form>
                    <FormInputs
                      ncols={['col-md-12']}
                      properties={[
                        {
                          label: 'Current Password',
                          type: 'password',
                          bsClass: 'form-control',
                          placeholder: 'Enter your password',
                          disabled: false,
                          onChange: this.onChange,
                          name: 'old_password',
                          value: this.state.old_password,
                          validationState: this.state.old_password
                            ? isLength(this.state.old_password, { max: 24, min: 6 })
                              ? 'success'
                              : 'error'
                            : null
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-12']}
                      properties={[
                        {
                          label: 'New Password',
                          type: 'password',
                          bsClass: 'form-control',
                          placeholder: 'Enter new password',
                          onChange: this.onChange,
                          name: 'password',
                          value: this.state.password,
                          validationState: this.state.old_password
                            ? !equals(this.state.old_password, this.state.password) && isLength(this.state.password, { max: 24, min: 6 })
                              ? 'success'
                              : 'error'
                            : null
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-12']}
                      properties={[
                        {
                          label: 'Verify New Password',
                          type: 'password',
                          bsClass: 'form-control',
                          placeholder: 'Verify new password',
                          onChange: this.onChange,
                          name: 'verify_password',
                          value: this.state.verify_password,
                          validationState: this.state.old_password
                            ? equals(this.state.verify_password, this.state.password) && isLength(this.state.verify_password, { max: 24, min: 6 })
                              ? 'success'
                              : 'error'
                            : null
                        }
                      ]}
                    />
                    <Button bsStyle='info'
                      disabled={old_password.length < 6 ||
                        password.length < 6 ||
                        verify_password.length < 6 ||
                        password != verify_password}
                      pullRight fill
                      type='button'
                      onClick={this.onSubmit}>
                      Submit
                    </Button>
                    <div className='clearfix' />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { changePassword })(UserProfile);
