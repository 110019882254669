/* eslint-disable */
/**
 *
 *  @description validate properties of ...source and merge them to target object
 * @param {Object} source
 * @returns merged object
 */
const mergeObject = (...source) => {
  let data = {};
  Object.assign(data, ...source);
  for (const iterator in data) {
    if (typeof data[iterator] !== 'boolean' && typeof data[iterator] !== 'number' && !data[iterator]) delete data[iterator];
  }
  return data;
};
export default mergeObject;
