import React, { Component } from "react";
import utils from "../helpers/utils";
import BaseTransaction from "./BaseTransaction";

class Deposit extends Component {
  render() {
    return (
      <BaseTransaction
        type="bonus"
        renderTitle={() => {
          return (
            <>
              <th>#</th>
              <th>Date</th>
              <th>
                User
                <br />
                (From User)
              </th>
              <th>Amount</th>
              <th>Investment Amount</th>
              <th>Level</th>
              <th>Status</th>
            </>
          );
        }}
        renderItem={(item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {utils.convertTimeToString(
                  item.createdAt,
                  "DD-MM-YYYY hh:mm:ss"
                )}
              </td>

              <td>
                <b>{item.user && item.user.email}</b>
                <br />({item.source_user && item.source_user.email})
              </td>

              <td>{utils.formatSeparator(item.amount, 1)}</td>
              <td>
                {item.investment &&
                  utils.formatSeparator(item.investment.amount || 0, 0)}
              </td>
              <td>{item.sub_type}</td>

              <td>
                <span
                  style={{
                    color:
                      item.status == "completed"
                        ? "green"
                        : item.status == "cancelled"
                        ? "red"
                        : "black",
                  }}
                >
                  {utils.toUpperFirstLetter(item.status)}
                </span>
              </td>
            </tr>
          );
        }}
      />
    );
  }
}

export default Deposit;
