import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
export class StatsCard extends Component {
  state = {
    hidden: true
  };
  render() {
    const isShow = !this.state.hidden || this.props.show;
    return (
      <div className='card card-stats'>
        <div className='content'>
          <Row>
            <Col xs={5}>
              <div className='icon-big text-center icon-warning'>{this.props.bigIcon}</div>
            </Col>
            <Col xs={7} className='ml-0'>
              <div className='numbers' title={this.props.statsText + '-' + this.props.statsValue}>
                <p>{this.props.statsText}</p>
                {this.props.statsValue}
              </div>
            </Col>
          </Row>
          {this.props.statsIcon && (
            <div className='footer'>
              <hr />
              <div className='stats'>
                {this.props.statsIcon} {this.props.statsIconText}
              </div>
            </div>
          )}
          {this.props.readmore && (
            <div className='footer'>
              <hr />
              {/* <span className='text-info cursor-pointer' onClick={() => this.setState({ hidden: !this.state.hidden })}>
                Read more
              </span> */}
              <span
                className={classnames({
                  'd-block': true,
                  'd-none': false
                })}
              >
                {this.props.readmore}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StatsCard;
