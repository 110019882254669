import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Moment from '../Commons/Moment';
import WaletTable from '../Table/WaletTable';

export default class WalletModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    date: PropTypes.string,
    rates: PropTypes.object,
    type: PropTypes.string.isRequired
  };

  render() {
    const { date, rates, type } = this.props;
    return (
      <Modal bsSize='large' show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{type.toUpperCase()}</Modal.Title>
        </Modal.Header>
        <div>
          <Row className='px-5 py-3'>
            <Col md={2}>Date:</Col>
            <Col md={10}>{<Moment date={date} format='DD/MM/YYYY' />}</Col>
          </Row>
        </div>
        {date && <WaletTable type={type} date={date} rate={rates} />}
        <Modal.Footer>
          <Button bsStyle='primary' onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
