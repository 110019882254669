import React, { Component } from 'react';
import { login } from '../redux/auth/auth.action';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import bg from 'assets/img/bg-login.jpg';
import { Redirect } from 'react-router-dom';
import { isEmail, isLength } from 'validator';
import { toast } from 'react-toastify';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      redirectToReferrer: false,
      isFetching: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) this.setState({ redirectToReferrer: true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.setState({ redirectToReferrer: true });
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    if (!isEmail(this.state.email)) return toast.error('Email is invalid!');
    if (!isLength(this.state.password, { max: 24, min: 6 })) return toast.error('Password is invalid!');

    this.setState({
      isFetching: true,
    });
    this.props.login(this.state).then(() => {
      this.setState({ isFetching: false });
    });
  }
  render() {
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let { redirectToReferrer } = this.state;
    if (redirectToReferrer) return <Redirect to={from} />;
    return (
      <div
        className='content'
        style={{ backgroundImage: 'url(' + bg + ')', backgroundSize: 'cover', position: 'fixed', width: '100%', height: '100%' }}>
        <Grid fluid>
          <Row>
            <Col md={6} smOffset={3} sm={12} className='margin-top'>
              <Card
                title='Login'
                content={
                  <form>
                    <FormInputs
                      ncols={['col-md-12']}
                      properties={[
                        {
                          label: 'Email',
                          type: 'email',
                          validationState: this.state.email ? (isEmail(this.state.email) ? 'success' : 'error') : null,
                          bsClass: 'form-control',
                          placeholder: 'Enter your email',
                          disabled: false,
                          onChange: this.onChange,
                          name: 'email',
                          value: this.state.email
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={['col-md-12']}
                      properties={[
                        {
                          label: 'Password',
                          type: 'password',
                          validationState: this.state.password ? (isLength(this.state.password, { max: 24, min: 6 }) ? 'success' : 'error') : null,
                          bsClass: 'form-control',
                          placeholder: 'Enter your password',
                          onChange: this.onChange,
                          name: 'password',
                          value: this.state.password
                        }
                      ]}
                    />
                    <Button bsStyle='info' pullRight fill type='button' onClick={this.onSubmit}
                      disabled={this.state.isFetching}>
                      {this.state.isFetching?'Login ...': 'Login'}
                    </Button>
                    <div className='clearfix' />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  //
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { login })(Login);
