import { SET_CURRENT_USER, PROFILE_LOADING, GET_RATES } from '../constants';

const initialState = {
  isAuthenticated: false,
  loading: false,
  info: {},
  rate: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !!action.payload,
        loading: false,
        info: action.payload
      };
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_RATES:
      return {
        ...state,
        rates: action.payload
      };
    default:
      return state;
  }
}
