import React, { Component } from 'react';
import { Grid, Row, Col, NavItem, Nav } from 'react-bootstrap';
import { StatsCard } from 'components/StatsCard/StatsCard.jsx';
import { dashboard } from '../helpers/api';
import moment from 'moment';
import utils from '../helpers/utils';

class Dashboard extends Component {
  state = {
    userStatistics: {
      "total_user": 0
    },
    statistics: {
      "withdraw": {
        "btc": 0,
        "eth": 0,
        "ocb": 0,
        "usdt": 0,
        "usd": 0
      },
      "deposit": {
        "btc": 0,
        "eth": 0,
        "ocb": 0,
        "usdt": 0,
        "usd": 0
      },
      "investment": 0,
      "commission": 0,
      "profit": 0,
      "exchange": {
        "btc": 0,
        "eth": 0,
        "ocb": 0,
        "usdt": 0,
        "usd": 0
      }
    },
    tab: 0
  };
  getRange = (tab = this.state.tab) => {
    switch (tab) {
      case 0:
        return [new Date(), new Date()];
      case 1:
        return [
          moment(new Date())
            .startOf('month')
            .toISOString(),
          new Date()
        ];
      case 2:
        return [
          moment(new Date())
            .startOf('year')
            .toISOString(),
          new Date()
        ];
      default:
        break;
    }
  };
  componentDidMount() {
    dashboard.getUserStatistics().then(({ data }) =>
      this.setState({
        userStatistics: data.data
      })
    );
    dashboard.getStatistics().then(({ data }) =>
      this.setState({
        statistics: data.data
      })
    );
  }

  changeTab = tab => {
    this.setState({ tab });
    dashboard.getStatistics(...this.getRange(tab)).then(({ data }) =>
      this.setState({
        statistics: data.data
      })
    );
  };

  render() {
    const { userStatistics, statistics, tab } = this.state;
    return (
      <div className='content'>
        <Grid fluid>
 
          <Row>
            {/* <Nav className='mb-3' bsStyle='tabs' justified activeKey={tab} onSelect={key => this.changeTab(key)}>
              <NavItem eventKey={0}>Day</NavItem>
              <NavItem eventKey={1}>Month</NavItem>
              <NavItem eventKey={2}>Year</NavItem>
            </Nav> */}

            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-cash text-info' />}
                statsText='Total Deposit'
                statsValue={utils.formatSeparator(statistics.deposit.usd,1)}
                show={true}
                readmore={
                  <Row>
                    <Col md={6}>BTC :{utils.formatSeparator(statistics.deposit.btc)}</Col>
                    <Col md={6}>ETH :{utils.formatSeparator(statistics.deposit.eth)}</Col>
                    <Col md={6}>OCB :{utils.formatSeparator(statistics.deposit.ocb)}</Col>
                    <Col md={6}>USD :{utils.formatSeparator(statistics.deposit.usd,1)}</Col>
                  </Row>
                }
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-cash text-info' />}
                statsText='Total Withdraw'
                statsValue={utils.formatSeparator(statistics.withdraw.usd,1)}
                readmore={
                  <Row>
                    <Col md={6}>BTC :{utils.formatSeparator(statistics.withdraw.btc)}</Col>
                    <Col md={6}>ETH :{utils.formatSeparator(statistics.withdraw.eth)}</Col>
                    <Col md={6}>OCB :{utils.formatSeparator(statistics.withdraw.ocb)}</Col>
                    <Col md={6}>USD :{utils.formatSeparator(statistics.withdraw.usd,1)}</Col>
                  </Row>
                }
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-cash text-info' />}
                statsText='Total Exchange'
                statsValue={utils.formatSeparator(statistics.exchange.usd,1)}
                readmore={
                  <Row>
                    <Col md={6}>BTC :{utils.formatSeparator(statistics.exchange.btc)}</Col>
                    <Col md={6}>ETH :{utils.formatSeparator(statistics.exchange.eth)}</Col>
                    <Col md={6}>OCB :{utils.formatSeparator(statistics.exchange.ocb)}</Col>
                    <Col md={6}>USD :{utils.formatSeparator(statistics.exchange.usd,1)}</Col>
                  </Row>
                }
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-cash text-info' />}
                statsText='Total investment (USDT)'
                statsValue={utils.formatSeparator(statistics.investment,1)}
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-cash text-info' />}
                statsText='Total Commission (USDT)'
                statsValue={utils.formatSeparator(statistics.commission,1)}
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-cash text-info' />}
                statsText='Total profit (USDT)'
                statsValue={utils.formatSeparator(statistics.profit,1)}
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-users text-info' />}
                statsText='Total User'
                statsValue={utils.formatSeparator(userStatistics.total_user,0)}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
