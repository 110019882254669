import React from 'react';
import PropTypes from 'prop-types';
import ReactMoment from 'react-moment';

function Moment({ fromNow, format = `DD/MM/YYYY H:mm:ss`, date, ...rest }) {
  return (
    <ReactMoment fromNow={fromNow} format={format} {...rest}>
      {date}
    </ReactMoment>
  );
}

Moment.propTypes = {
  locale: PropTypes.string,
  fromNow: PropTypes.bool,
  format: PropTypes.string,
  date: PropTypes.string
};

export default Moment;
