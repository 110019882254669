import axios from "axios";
import mergeObject from "../merge-object";

export default {
  getAccounts({
    search_text,
    limit,
    page,
    sort = "-createdAt",
    select,
    includes_kyc_status,
  }) {
    return axios.get("/api/auth/admin/get-accounts", {
      params: mergeObject({
        search_text,
        limit,
        page,
        select,
        sort,
        includes_kyc_status,
      }),
    });
  },

  updateUserState(id, status) {
    return axios
      .post("/api/auth/admin/change-account-by-id?id=" + id, { status })
      .catch((err) => false);
  },

  getUserInfoById(id) {
    return axios.get("/api/user/admin/get-user-info-by-id?id=" + id);
  },

  changeKycStatus(_id,status) {
    return axios.post(`/api/user/admin/change-kyc-status?_id=${_id}&kyc_status=${status}`);
  },
  resetPassword(email) {
    return axios.post(`/api/auth/admin/reset-password?email=${email}`);
  },
  turnOffTfa(email) {
    return axios.post(`/api/auth/admin/turn-off-tfa?email=${email}`);
  },
};
