import React, { Component } from 'react';
import ReactSwitch from 'react-switch';

class Switch extends Component {
  render() {
    const { isChecked, onChange, name, label } = this.props;
    return (
      <label>
        <ReactSwitch
          onColor='#86d3ff'
          onHandleColor='#2693e6'
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          height={13}
          width={32}
          className='react-switch'
          onChange={e => onChange(e, name)}
          checked={isChecked}
        />
        <label>{label}</label>
      </label>
    );
  }
}

export default Switch;
