import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  NavItem,
  Nav,
  Button,
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
  DropdownButton,
  Dropdown,
  MenuItem,
  Tabs,
  Tab,
} from "react-bootstrap";
import Moment from "../Commons/Moment";
import utils from "helpers/utils";
import userApi from "../../helpers/api/user";
import walletApi from "../../helpers/api/wallet";
import { toast } from "react-toastify";
import StatsCard from "../StatsCard/StatsCard";
import { FormInputs } from "../FormInputs/FormInputs.jsx";

import LoadingModal from "./LoadingModel";
import user from "../../helpers/api/user";

export default class UserWalletModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    account: PropTypes.object,
  };

  state = {
    userDetail: {},
    wallet: [],
    depositWallet: "",
    depositAmount: "",

    investWallet: {},
    selectedPackage: {},
    investPackages: [],
    isFetching: false,
  };

  kycTypes = {
    nic: "National Identity Card",
    passport: "Passport",
    driving_license: "Driving License",
  };

  kycFieldsMap = {
    kyc_first_name: "First Name",
    kyc_middle_name: "Middle Name",
    kyc_last_name: "Last Name",
    kyc_gender: "Gender",
    kyc_citizenship: "Citizenship",
    kyc_birthday: "Birthday",
    kyc_country: "Country",
    kyc_province: "Province",
    kyc_city: "City",
    kyc_address: "Address",
    kyc_postal_code: "Postal Code",
    kyc_type: "Kyc Type",
    kyc_expiration_date: "Expiration Date",
  };

  constructor(props) {
    super(props);

    this.getUserDetail(props.account);
    walletApi
      .getPackages()
      .then((res) => {
        this.setState({
          investPackages: res.data.data,
        });
      })
      .catch((err) => {
        toast.error("Get invest packages error");
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account && nextProps.account != this.props.account) {
      this.getUserDetail(nextProps.account.user);
    }
  }

  getUserDetail(user) {
    this.setState({
      wallet: [],
      userDetail: [],
      depositAmount: "",
      depositWallet: {},
      investWallet: {},
    });
    if (!user) {
      return;
    }

    userApi
      .getUserInfoById(user._id)
      .then((res) => {
        this.setState({
          userDetail: res.data.data,
        });
      })
      .catch((error) => {
        toast.error("Get user info error");
        console.log(error.response);
      });

    walletApi
      .getWalletById(user._id)
      .then((res) => {
        this.setState({
          wallet: res.data.data,
        });
      })
      .catch((err) => {
        toast.error("Get user wallet error");
      });
  }

  deposit = () => {
    const { depositAmount, depositWallet, userDetail } = this.state;
    if (!depositWallet._id) {
      toast.warn("Please select wallet");
      return;
    }
    if (depositAmount == "") {
      toast.warn("Please enter amount");
      return;
    }

    if (!userDetail._id) {
      toast.warn("Please waiting for getting user info");
      return;
    }

    this.setState({
      isFetching: true,
    });
    walletApi
      .depositForUser(userDetail._id, depositAmount, depositWallet._id)
      .then((res) => {
        toast.success("Deposit successfully");
        this.setState({
          isFetching: false,
        });
        this.getUserDetail(userDetail);
      })
      .catch((err) => {
        const message =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Deposit failure";
        toast.error(message);
        this.setState({
          isFetching: false,
        });
      });
  };

  invest = () => {
    const { investWallet, selectedPackage, userDetail } = this.state;
    if (!investWallet._id) {
      toast.warn("Please select wallet");
      return;
    }
    if (!selectedPackage._id) {
      toast.warn("Please select package");
      return;
    }

    // if (investWallet.amount < selectedPackage.amount) {
    //   toast.warn('The balance is not enough');
    //   return;
    // }

    if (!userDetail._id) {
      toast.warn("Please waiting for getting user info");
      return;
    }

    this.setState({
      isFetching: true,
    });
    walletApi
      .investForUser(userDetail._id, selectedPackage._id, investWallet._id)
      .then((res) => {
        toast.success("Invest successfully");
        this.getUserDetail(userDetail);
        this.setState({
          isFetching: false,
        });
      })
      .catch((err) => {
        const message =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Invest failure";
        toast.error(message);
        this.setState({
          isFetching: false,
        });
      });
  };

  changeStatus = (status) => {
    const { userDetail, wallet, isFetching } = this.state;
    if (this.props.onChangeKycStatus) {
      this.props.onChangeKycStatus(userDetail._id, status);
    }
  };

  resetPassword = () => {
    const { userDetail, wallet, isFetching } = this.state;
    userApi.resetPassword(userDetail.email).then(response=>{
      if(response && response.data && response.data.code === 200){
        alert('New password is: ' + response.data.data);
        return;
      }
      alert('Failure!');

    }).catch(err=>{
      alert('Failure!');
    })
  };

  turnOffTfa = () => {
    const { userDetail, wallet, isFetching } = this.state;
    userApi.turnOffTfa(userDetail.email).then(response=>{
      if(response && response.data && response.data.code === 200){
        alert('Successfully!');
        return;
      }
      alert('Failure!');

    }).catch(err=>{
      alert('Failure!');
    })
  };

  render() {
    const { userDetail, wallet, isFetching } = this.state;
    const { account } = this.props;
    if (isFetching) {
      return <LoadingModal show={true} />;
    }
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>User Info</Modal.Title>
        </Modal.Header>
        <div>
          <Row className="px-5 py-3">
            <Col md={4}>Created Date:</Col>
            <Col md={8}>
              <b>{account && <Moment date={account.createdAt} />}</b>
            </Col>
            <Col md={4}>Email:</Col>
            <Col md={8}>
              <b>{account && account.email}</b>
            </Col>
            <Col md={4}>Total investment:</Col>
            <Col md={8}>
              <b>{utils.formatSeparator(userDetail.total_investment)} USDT</b>
            </Col>
            <Col md={4}>Sponsor:</Col>
            <Col md={8}>
              <b>{userDetail.sponsor ? userDetail.sponsor.email : "None"}</b>
            </Col>
            <Col md={4}>Kyc Status:</Col>
            <Col md={8}>
              <b>{utils.toUpperFirstLetter(userDetail.kyc_status)}</b>
            </Col>
          </Row>
          <div className="col-12 mx-3 row justify-content-center">
            {userDetail.kyc_status !== "none" && (
              <Button
                bsStyle="primary"
                style={{padding: "4px 8px", }}
                className="mx-3"
                onClick={() => this.changeStatus("approved")}
              >
                Approve KYC
              </Button>
            )}
            {userDetail.kyc_status !== "none" && (
              <Button
                bsStyle="danger"
                style={{padding: "4px 8px", }}
                className="mx-3"
                onClick={() => this.changeStatus("rejected")}
              >
                Reject KYC
              </Button>
            )}
            <Button
                bsStyle="primary"
                style={{padding: "4px 8px", }}
                className="mx-3"
                onClick={() => this.resetPassword()}
              >
                Reset Password
              </Button>
              <Button
                bsStyle="primary"
                style={{padding: "4px 8px", }}
                className="mx-3"
                onClick={() => this.turnOffTfa()}
              >
                Turn off 2FA
              </Button>
          </div>
        </div>
        <hr />

        <Row className="mx-0">
          <Tabs
            defaultActiveKey={this.props.defaultTabIndex || 1}
            id="uncontrolled-tab-example"
          >
            <Tab eventKey={1} title="Wallet">
              {wallet.map((item) => (
                <Col md={6} className="px-2 py-2">
                  <div
                    style={{ background: "#b2c4ea", borderRadius: "8px" }}
                    className="px-3 py-1"
                  >
                    <div>
                      <b>
                        {`${utils.formatSeparator(
                          item.amount
                        )} ${item.currency.toUpperCase()}`}
                      </b>
                    </div>
                    <div
                      style={{
                        wordWrap: "break-word",
                      }}
                    >
                      {item.address || "None"}
                    </div>
                  </div>
                </Col>
              ))}
            </Tab>
            <Tab eventKey={2} title="Deposit & Invest">
              <div className="px-3" style={{ fontWeight: "bold" }}>
                Invest
              </div>
              <Row className="px-3 mb-3">
                <Col md={12}>
                  <DropdownButton
                    justified
                    title={
                      this.state.selectedPackage.amount
                        ? utils.formatSeparator(
                            this.state.selectedPackage.amount,
                            0
                          ) + " USDT"
                        : "Package"
                    }
                    onSelect={(e) => {
                      console.log("Select ", e);
                      this.setState({
                        selectedPackage: e,
                      });
                    }}
                  >
                    {this.state.investPackages.map((item) => {
                      return (
                        <MenuItem eventKey={item}>{item.amount} USDT</MenuItem>
                      );
                    })}
                  </DropdownButton>
                  <DropdownButton
                    className="ml-5"
                    title={
                      this.state.investWallet.currency
                        ? this.state.investWallet.currency.toUpperCase()
                        : "Wallet"
                    }
                    onSelect={(e) => {
                      this.setState({
                        investWallet: e,
                      });
                    }}
                  >
                    {wallet.map((item) => {
                      if (item.currency == "usdt") {
                        return null;
                      }

                      return (
                        <MenuItem eventKey={item}>
                          {item.currency.toUpperCase()}
                        </MenuItem>
                      );
                    })}
                  </DropdownButton>
                  <Button
                    bsStyle="primary"
                    onClick={this.invest}
                    className="ml-5"
                    disabled={
                      !this.state.investWallet.currency ||
                      !this.state.selectedPackage._id
                    }
                  >
                    Submit
                  </Button>
                </Col>
              </Row>

              <hr />
              <div className="px-3" style={{ fontWeight: "bold" }}>
                Deposit
              </div>
              <Row className="px-3 mb-3">
                <Col md={6}>
                  <FormControl
                    type="number"
                    min={0}
                    placeholder="Amount USD"
                    value={this.state.depositAmount}
                    onChange={(e) =>
                      this.setState({ depositAmount: e.target.value })
                    }
                  />
                  <small className="text-danger">
                    {this.state.depositWallet.currency == "eth" ||
                    this.state.depositWallet.currency == "ocb"
                      ? `We will send ${this.state.depositWallet.currency.toUpperCase()} from your address to user's address`
                      : ""}
                  </small>
                </Col>
                <Col md={4} sm={6}>
                  <DropdownButton
                    title={
                      this.state.depositWallet.currency
                        ? this.state.depositWallet.currency.toUpperCase()
                        : "To Wallet"
                    }
                    onSelect={(e) => {
                      this.setState({
                        depositWallet: e,
                      });
                    }}
                  >
                    {wallet.map((item) => (
                      <MenuItem eventKey={item}>
                        {item.currency.toUpperCase()}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                  <Button
                    bsStyle="primary"
                    onClick={this.deposit}
                    className="mx-5"
                    disabled={
                      this.state.depositWallet.currency == "" ||
                      this.state.depositAmount == ""
                    }
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={3} title="KYC">
              <div className="px-3 py-3">
                {Object.keys(this.kycFieldsMap).map((item) => {
                  if (!userDetail[item]) {
                    return null;
                  }
                  let value = "None";
                  if (item === "kyc_type") {
                    value = this.kycTypes[userDetail[item]];
                  } else if (
                    item === "kyc_birthday" ||
                    item === "kyc_expiration_date"
                  ) {
                    value = utils.convertTimeToString(
                      userDetail[item],
                      "DD/MM/YYYY"
                    );
                  } else {
                    value = userDetail[item];
                  }
                  return (
                    <div className="row mx-0 ">
                      <div className="col-6 col-md-4">
                        {this.kycFieldsMap[item]}
                      </div>
                      <div className="col-6 col-md-8">
                        <b>{value}</b>
                      </div>
                    </div>
                  );
                })}
              </div>
              {userDetail["kyc_selfie_image"] ? (
                <div className="row mx-0 px-3 py-3">
                  <div className="col-12 col-md-4">Selfie</div>
                  <div className="col-12 col-md-8">
                    <a
                      href={userDetail["kyc_selfie_image"]}
                      target="_blank"
                      rel="no ref"
                    >
                      <img
                        src={userDetail["kyc_selfie_image"]}
                        style={{
                          maxWidth: "100%",
                          // maxHeight: '300px'
                        }}
                        alt={userDetail.email + " selfie image"}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
              {userDetail["kyc_front_image"] ? (
                <div className="row mx-0 px-3 py-3">
                  <div className="col-12 col-md-4">Front of Card</div>
                  <div className="col-12 col-md-8">
                    <a
                      href={userDetail["kyc_front_image"]}
                      target="_blank"
                      rel="no ref"
                    >
                      <img
                        src={userDetail["kyc_front_image"]}
                        style={{
                          maxWidth: "100%",
                          // maxHeight: '300px'
                        }}
                        alt={userDetail.email + " front image"}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
              {userDetail["kyc_back_image"] ? (
                <div className="row mx-0 px-3 py-3">
                  <div className="col-12 col-md-4">Back of Card</div>
                  <div className="col-12 col-md-8">
                    <a
                      href={userDetail["kyc_back_image"]}
                      target="_blank"
                      rel="no ref"
                    >
                      <img
                        src={userDetail["kyc_back_image"]}
                        style={{
                          maxWidth: "100%",
                          // maxHeight: '300px'
                        }}
                        alt={userDetail.email + " back image"}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
            </Tab>
          </Tabs>
        </Row>
        <Row className="px-3"></Row>

        <Modal.Footer>
          {userDetail.kyc_status !== "none" && (
            <Button
              bsStyle="primary"
              onClick={() => this.changeStatus("approved")}
            >
              Approve KYC
            </Button>
          )}
          {userDetail.kyc_status !== "none" && (
            <Button
              bsStyle="danger"
              onClick={() => this.changeStatus("rejected")}
            >
              Reject KYC
            </Button>
          )}

          <Button bsStyle="primary" onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
