import _ from 'lodash';

export const createAction = (type, payload = {}) => ({ type, payload });

export const convertTimeToString = (time, format) => {
	const date = new Date(time);
	const year = date.getFullYear();
	const mon = ("0" + (date.getMonth() + 1)).slice(-2);
	const day = ("0" + date.getDate()).slice(-2);
	const hour = ("0" + date.getHours()).slice(-2);
	const min = ("0" + date.getMinutes()).slice(-2);
	const second = ("0" + date.getSeconds()).slice(-2);

	return format
		.replace("YYYY", year)
		.replace("yyyy", year)
		.replace("dd", day)
		.replace("DD", day)
		.replace("MM", mon)
		.replace("hh", hour)
		.replace("mm", min)
		.replace("ss", second);
};

export function getHumanReadableNumber(number, fixed = 2) {
	if (!number) {
		return 0;
	}
	const postfix = ["K", "M", "B", "T"];
	const arrange = [1000, 1000000, 1000000000, 1000000000000];
	for (let i = arrange.length - 1; i >= 0; i--) {
		if (number > arrange[i]) {
			number = (number / arrange[i]).toFixed(fixed) + postfix[i];
			break;
		}
	}

	return number;
}

export const formatSeparator = (num, fixed = 4) => {
	if(!num){
		return 0;
	}
	const parts = num.toFixed(fixed).split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
};

export const formatNumber = currency => {
	return parseInt(currency.replace(/,/g, ""));
};

export const hexToRgba = (hex, metric) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return (
			"rgba(" +
			[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
			"," +
			metric +
			")"
		);
	}
	throw new Error("Bad Hex");
};

export const getExistedFields = (data) => {
	const fields = [];
	const value = {};
	for (let key in data) {
		if (typeof (data[key]) != 'undefined') {
			fields.push(key);
			value[key] = data[key];
		}
	}
	return ({ fields, value });
}

export const pick = (data, requestedFields = []) => {
	const fields = [];
	const value = {};
	requestedFields.forEach(key => {
		if (typeof (data[key]) != 'undefined') {
			fields.push(key);
			value[key] = data[key];
		}
	});
	return ({ fields, value });
}

// export const validate = (joiSchema, data, fields) => {
// 	const result = pick(data, fields);
// 	const { error, value } = Joi.object(pick(joiSchema, fields).value).validate(result.value, { abortEarly: true });
// 	if (error) {
// 		const path = (error.details) ? error.details : [];
// 		console.log('error',error);
// 		const errorFields = path.map(item => {
// 			console.log('item',item);
// 			const type = item.type === 'any.required' ?
// 				'required' :
// 				'invalid';
// 			return {
// 				reason: type,
// 				domain: item.context.key,
// 				message: item.message,
// 			};
// 		});
// 		return {
// 			error: errorFields,
// 			value
// 		};
// 	} else {
// 		return { error: null, value };
// 	}
// }

export function toUpperFirstLetter(string) {
	if (!string || string === '') {
		return string;
	}

	return string.slice(0, 1).toUpperCase() + string.slice(1);
}

/**
 * Checks if the given string is an address
 *
 * @method isEthAddress
 * @param {String} address the given HEX adress
 * @return {Boolean}
*/
var isEthAddress = function (address) {
	if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
		// check if it has the basic requirements of an address
		return false;
	} else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
		// If it's all small caps or all all caps, return true
		return true;
	} else {
		// Otherwise check each case
		return true;
	}
};


/**
 * The below function use to validate BTC Address
 * @param {} address 
 */
function isBtcAddress(address) {
	return address && address.length >= 20;
}


function getStartTimeOfMonth(month, year) {
	var firstDay = new Date(year, month, 1, 0, 0, 0, 1);
	return firstDay.getTime();
  }
  
  function getEndTimeOfMonth(month, year) {
	var firstDay = new Date(year, month + 1, 1, 0, 0, 0, 0);
	return firstDay.getTime();
  }
export default {
	createAction,
	convertTimeToString,
	getHumanReadableNumber,
	formatNumber,
	formatSeparator,
	hexToRgba,
	getExistedFields,
	pick,
	toUpperFirstLetter,
	isEthAddress,
	isBtcAddress,
	getStartTimeOfMonth,
	getEndTimeOfMonth,
};
