import Dashboard from 'views/Dashboard.jsx';
import UserProfile from 'views/UserProfile.jsx';
/* import TableList from 'views/TableList.jsx';
import Typography from 'views/Typography.jsx';
import Icons from 'views/Icons.jsx';
import Maps from 'views/Maps.jsx';
import Notifications from 'views/Notifications.jsx'; */
import Users from './views/Users';
import Deposit from './views/Deposit';
import Withdraw from './views/Withdraw';
import Profit from './views/Profit';
import Investment from './views/Investment';
import Commission from './views/Commission';
import Exchange from './views/Exchange';
import Setting from './views/Setting';
import Kyc from './views/Kyc';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'pe-7s-graph',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'pe-7s-users',
    component: Users,
    layout: '/admin'
  },
  {
    path: '/kycs',
    name: 'Kyc',
    icon: 'pe-7s-users',
    component: Kyc,
    layout: '/admin'
  },
  {
    path: '/deposit',
    name: 'Deposit',
    icon: 'pe-7s-server',
    component: Deposit,
    layout: '/admin'
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    icon: 'pe-7s-paper-plane',
    component: Withdraw,
    layout: '/admin'
  },
  {
    path: '/investment',
    name: 'Investment',
    icon: 'pe-7s-paper-plane',
    component: Investment,
    layout: '/admin'
  },
  {
    path: '/profilt',
    name: 'Profilt',
    icon: 'pe-7s-paper-plane',
    component: Profit,
    layout: '/admin'
  },
  {
    path: '/commission',
    name: 'Commission',
    icon: 'pe-7s-paper-plane',
    component: Commission,
    layout: '/admin'
  },
  {
    path: '/exchange',
    name: 'Exchange',
    icon: 'pe-7s-paper-plane',
    component: Exchange,
    layout: '/admin'
  },
  {
    path: '/user',
    name: 'Admin Profile',
    icon: 'pe-7s-user',
    component: UserProfile,
    layout: '/admin'
  },
  {
    path: '/setting',
    name: 'Setting',
    icon: 'pe-7s-settings',
    component: Setting,
    layout: '/admin'
  }
  /*   {
    path: '/table',
    name: 'Table List',
    icon: 'pe-7s-note2',
    component: TableList,
    layout: '/admin'
  },
  {
    path: '/typography',
    name: 'Typography',
    icon: 'pe-7s-news-paper',
    component: Typography,
    layout: '/admin'
  },
  {
    path: '/icons',
    name: 'Icons',
    icon: 'pe-7s-science',
    component: Icons,
    layout: '/admin'
  },
  {
    path: '/maps',
    name: 'Maps',
    icon: 'pe-7s-map-marker',
    component: Maps,
    layout: '/admin'
  },
  {
    path: '/notifications',
    name: 'Notifications',
    icon: 'pe-7s-bell',
    component: Notifications,
    layout: '/admin'
  } */
];

export default dashboardRoutes;
