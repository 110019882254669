import PropTypes from 'prop-types';

function USD(props) {
  const value = props.value || 0;
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

USD.propTypes = {
  value: PropTypes.number.isRequired
};

export default USD;
