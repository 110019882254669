import jwt_decode from "jwt-decode";
import { setAuthToken } from "./config";
import { getCurrentUser, logout } from "../redux/auth/auth.action";

const getAuth = (store) => {
  if (localStorage.auth && localStorage['auth-expired-at']) {
    try {
      const expiredAt = parseInt(localStorage['auth-expired-at']);
      if(expiredAt < Date.now() + 5*60000){
        return;
      }

      setAuthToken(localStorage.auth);
      getCurrentUser(store.dispatch);
    } catch (err) {
      console.log("GetAuth error", err);
    }
  }
};
export default getAuth;
