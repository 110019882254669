import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { wallet } from "../helpers/api";
import USD from "../components/Commons/USD";
import moment from "moment";
import ReactPaginate from "react-paginate";
import WalletModal from "../components/Modals/WalletModal";
import utils from "../helpers/utils";

const limit = 15;
class BaseTransaction extends Component {
  state = {
    list: [],
    date: new Date(),
    rates: null,
    show: false,
    currentDate: null,
    page: 1,
    pages: 1,
    search_text: "",
    isFetching: true,
  };

  componentDidMount() {
    wallet
      .getTransactions({
        limit,
        page: 1,
        type: this.props.type,
        search_text: this.state.search_text,
      })
      .then((res) =>
        this.setState({
          list: res.data.data,
          pages: res.data.total_page,
          isFetching: false,
        })
      );
  }

  clearSearch = (e) => {
    e.preventDefault();
    this.setState({ search_text: "" }, () => {
      this.search(e);
    });
  };

  search = (e) => {
    e.preventDefault();
    this.setState({ isFetching: true });
    wallet
      .getTransactions({
        limit: 15,
        page: 1,
        type: this.props.type,
        search_text: this.state.search_text,
      })
      .then((res) =>
        this.setState({
          list: res.data.data,
          pages: res.data.total_page,
          isFetching: false,
        })
      );
  };

  onPageChange = (data) => {
    let page = data.selected + 1;

    this.setState(
      {
        page,
        list: [],
        isFetching: true,
      },
      () => {
        wallet
          .getTransactions({
            limit: 15,
            page,
            type: this.props.type,
            search_text: this.state.search_text,
          })
          .then((res) =>
            this.setState({
              list: res.data.data,
              pages: res.data.total_page,
              isFetching: false,
            })
          );
      }
    );
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (date) => {
    this.setState({ show: true, currentDate: date });
  };
  render() {
    const { list, currentDate, show, page, pages } = this.state;

    return (
      <div className="content">
        {/* <WalletModal type={'deposit'} date={currentDate} show={show} handleClose={this.handleClose}></WalletModal> */}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={utils.toUpperFirstLetter(this.props.type)}
                ctTableFullWidth
                ctTableResponsive
                extra={
                  <form style={{ marginLeft: "1em" }} onSubmit={this.search}>
                    <input
                      value={this.state.search_text}
                      onChange={(event) =>
                        this.setState({ search_text: event.target.value })
                      }
                      placeholder={"Username or email"}
                    />
                    <button>Search</button>
                    <button onClick={this.clearSearch}>Clear</button>
                  </form>
                }
                content={
                  this.state.isFetching ? (
                    <div style={{ textAlign: "center", paddingTop: "3em" }}>
                      Loading ...
                    </div>
                  ) : (
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          {this.props.renderTitle ? (
                            this.props.renderTitle()
                          ) : (
                            <>
                              <th>Date</th>
                              <th>User</th>
                              <th>Amount</th>
                              <th>Currency</th>
                              <th>Hash</th>
                              <th>Status</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, key) => {
                          if (this.props.renderItem) {
                            return this.props.renderItem(
                              item,
                              key + (this.state.page - 1) * limit
                            );
                          }
                          return (
                            <tr key={key}>
                              <td>
                                {utils.convertTimeToString(
                                  item.createdAt,
                                  "DD-MM-YYYY hh:mm:ss"
                                )}
                              </td>
                              <td>{item.user && item.user.email} </td>
                              <td>{utils.formatSeparator(item.amount)}</td>
                              <td>{item.currency.toUpperCase()}</td>
                              <td>
                                {item.tp_transaction?<a
                                  target="_blank"
                                  href={
                                    "https://etherscan.io/tx/" +
                                    item.tp_transaction
                                  }
                                >
                                  View
                                </a>: null}
                              </td>
                              <td>
                                <span
                                  style={{
                                    color:
                                      item.status == "completed"
                                        ? "green"
                                        : item.status == "cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {" "}
                                  {utils.toUpperFirstLetter(item.status)}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )
                }
                legend={
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="page-link rounded-circle"
                    pageCount={pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.onPageChange}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link rounded-circle"
                    previousClassName="page-item"
                    previousLinkClassName="page-link rounded-circle"
                    nextClassName="page-item "
                    nextLinkClassName="page-link rounded-circle"
                    activeClassName="active"
                    forcePage={+page - 1}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default BaseTransaction;
