import React, { Component } from 'react';
import BaseTransaction from './BaseTransaction';

class Deposit extends Component {
  render() {
    return (
      <BaseTransaction type="exchange"/>
    );
  }
}

export default Deposit;
