import axios from 'axios';

export default {
  getUserStatistics() {
    return axios.get('/api/user/admin/get-statistics');
  },
  getStatistics(start_date, end_date) {
    return axios.get('/api/wallet/admin/get-statistics', { params: { start_date, end_date } });
  }
};
