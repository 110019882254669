import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { wallet } from "../helpers/api";
import dotObject from "dot-object";
import { toast } from "react-toastify";
import Switch from "../components/Switch/Switch";
import { isFloat } from "validator";
import _ from "lodash";

class Setting extends Component {
  defaultConfig = {
    coinHolderAddress: "0x4d6E23705304eFa6B4F00F381fdD7C35593d91F8",
    coinPrice: 1,

    //supported coins
    supportedWallets: ["btc", "ocb", "eth", "profit", "bonus"],

    maxInvestmentPackageCount: 1,
    allowInvest: true,
    allowInvestV2: {
      eth: true,
      btc: true,
      ocb: true,
      trx: true,
    },
    allowExchange: {
      profit_ocb: true,
      profit_eth: true,
      profit_btc: true,
      profit_trx: true,
      bonus_ocb: true,
      bonus_eth: true,
      bonus_btc: true,
      bonus_trx: true,
    },
    exchangeFee: {
      profit_ocb: 0,
      profit_eth: 0,
      profit_btc: 0,
      profit_trx: 0,
      bonus_ocb: 0,
      bonus_eth: 0,
      bonus_btc: 0,
      bonus_trx: 0,
    },
    bonusByLevel: {
      f1: 0.05,
      f2: 0.04,
      f3: 0.03,
      f4: 0.02,
      f5: 0.01,
    },
    withdrawFee: {
      ocb: 0,
      eth: 0,
      btc: 0,
      trx: 0,
    },
    allowWithdraw: {
      btc: true,
      eth: true,
      ocb: true,
      trx: true,
    },
    minEthToWithdrawOcb: 0.008,
    withdrawInvestment: {
      maxPercentInWithdrawal: 0.2,
      periodBetweenTwoWithdrawal: 10 * 86400000, //10 days
    },

    databaseVersion: 2,
    latestAndroidAppVersion: 20200814,
    latestIosAppVersion: 20200814,
  };
  state = {
    ...dotObject.dot(this.defaultConfig),

    sendState: {},
  };

  componentDidMount() {
    wallet.getSetting().then(({ data }) => {
      const settings = dotObject.dot(data.data);
      this.setState(settings);
      console.log("get config success", settings);
    });
  }

  onChange = (e, name) => {
    name
      ? this.setState({ [name]: e })
      : this.setState({ [e.target.name]: e.target.value });
    console.log("Change", e, name);
  };

  standardNumber(number) {
    return Math.round(number * 10 ** 6) / 10 ** 6;
  }

  changePrice = (e) => {
    e.preventDefault();
    this.changeSetting(["coinPrice"]);
  };

  changeWithdrawSetting = (e) => {
    e.preventDefault();

    this.changeSetting([
      "allowWithdraw.btc",
      "allowWithdraw.eth",
      "allowWithdraw.ocb",
      "allowWithdraw.trx"
    ]);
  };

  changeInvestSetting = (e) => {
    e.preventDefault();

    this.changeSetting([
      "allowInvestV2.btc",
      "allowInvestV2.eth",
      "allowInvestV2.ocb",
      "allowInvestV2.trx",
    ]);
  };

  changeSwapSetting = (e) => {
    e.preventDefault();

    this.changeSetting([
      "allowExchange.profit_btc",
      "allowExchange.profit_eth",
      "allowExchange.profit_ocb",
      "allowExchange.profit_trx",
      "allowExchange.bonus_btc",
      "allowExchange.bonus_eth",
      "allowExchange.bonus_ocb",
      "allowExchange.bonus_trx",
      "exchangeFee.profit_btc",
      "exchangeFee.profit_eth",
      "exchangeFee.profit_ocb",
      "exchangeFee.profit_trx",
      "exchangeFee.bonus_btc",
      "exchangeFee.bonus_eth",
      "exchangeFee.bonus_ocb",
      "exchangeFee.bonus_trx",
    ]);
  };

  changeSetting = (fields) => {
    const data = _.pick(this.state, fields);
    console.log("Update data", data);
    wallet
      .updateSetting(data)
      .then((response) => {
        const settings = dotObject.dot(response.data.data);
        const data = _.pick(settings, fields);
        this.setState({
          ...data,
        });
        toast.success("Update successfully!");
      })
      .catch((err) => toast.error("Update failure!"));
  };

  resetSendState = ()=>{
    wallet.resetSendState().then(response=>{
      if(response && response.data && response.data.code === 200){
        alert('Successfully!');
      }else {
        alert('Failure!');
      }
    });
  }
  

  getSendState = () => {
    wallet.getSendState().then(response=>{
      if(response && response.data && response.data.code === 200){
        this.setState({
          sendState: response.data.data,
        })
      }
    });
  }

  render() {
    const state = this.state;
    return (
      <div className="content">
        <div className="row mx-0">
          <div className="col-12 col-md-6 my-3">
            <form className=" px-5 py-3 bg-white rounded">
              <FormInputs
                ncols={["col-12", "col-12", "col-12"]}
                properties={[
                  {
                    label: `OCB Holder  Address`,
                    type: "text",
                    bsClass: "form-control",
                    name: "coinHolderAddress",
                    value: state.coinHolderAddress,
                    disabled: true,
                    // onChange: this.onChange,
                    validationState: "success",
                  },

                  {
                    label: `TRON Holder Address`,
                    type: "text",
                    bsClass: "form-control",
                    name: "tronHolderAddressBase58",
                    value: state.tronHolderAddressBase58,
                    disabled: true,
                    // onChange: this.onChange,
                    validationState: "success",
                  },

                  {
                    label: `OCB Price`,
                    type: "text",
                    bsClass: "form-control",
                    name: "coinPrice",
                    value: state.coinPrice,
                    onChange: this.onChange,
                    validationState:
                      state.coinPrice &&
                      isFloat(state.coinPrice.toString(), {
                        min: 0,
                      })
                        ? "success"
                        : "error",
                  },

                ]}
              />

              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                onClick={this.changePrice}
              >
                Update
              </Button>
              <div className="clearfix" />
            </form>
          </div>

          <div className="col-12 col-md-6 my-3">
            <form className="bg-white rounded px-5 py-3">
              <div className="w-100 mb-3">
                <b>Withdraw Config</b>
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowWithdraw.btc"
                  isChecked={state["allowWithdraw.btc"]}
                  onChange={this.onChange}
                  label="Allow Withdraw BTC"
                />
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowWithdraw.eth"
                  isChecked={state["allowWithdraw.eth"]}
                  onChange={this.onChange}
                  label="Allow Withdraw ETH"
                />
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowWithdraw.ocb"
                  isChecked={state["allowWithdraw.ocb"]}
                  onChange={this.onChange}
                  label="Allow Withdraw OCB"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowWithdraw.trx"
                  isChecked={state["allowWithdraw.trx"]}
                  onChange={this.onChange}
                  label="Allow Withdraw TRX"
                />
              </div>

              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                onClick={this.changeWithdrawSetting}
              >
                Update
              </Button>
              <div className="clearfix" />
            </form>
          </div>

          <div className="col-12 col-md-6 my-3">
            <form className="bg-white rounded px-5 py-3">
              <div className="w-100 mb-3">
                <b>Lending Config</b>
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowInvestV2.btc"
                  isChecked={state["allowInvestV2.btc"]}
                  onChange={this.onChange}
                  label="Allow Lending BTC"
                />
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowInvestV2.eth"
                  isChecked={state["allowInvestV2.eth"]}
                  onChange={this.onChange}
                  label="Allow Lending ETH"
                />
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowInvestV2.ocb"
                  isChecked={state["allowInvestV2.ocb"]}
                  onChange={this.onChange}
                  label="Allow Lending OCB"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowInvestV2.trx"
                  isChecked={state["allowInvestV2.trx"]}
                  onChange={this.onChange}
                  label="Allow Lending TRX"
                />
              </div>

              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                onClick={this.changeInvestSetting}
              >
                Update
              </Button>
              <div className="clearfix" />
            </form>
          </div>

          <div className="col-12 col-md-6 my-3">
            <form className="bg-white rounded px-5 py-3">
              <div className="w-100 mb-3">
                <b>Swap Config</b>
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.profit_btc"
                  isChecked={state["allowExchange.profit_btc"]}
                  onChange={this.onChange}
                  label="Allow Swap Profit -> BTC"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.profit_eth"
                  isChecked={state["allowExchange.profit_eth"]}
                  onChange={this.onChange}
                  label="Allow Swap Profit -> ETH"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.profit_ocb"
                  isChecked={state["allowExchange.profit_ocb"]}
                  onChange={this.onChange}
                  label="Allow Swap Profit -> OCB"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.profit_trx"
                  isChecked={state["allowExchange.profit_trx"]}
                  onChange={this.onChange}
                  label="Allow Swap Profit -> TRX"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.bonus_btc"
                  isChecked={state["allowExchange.bonus_btc"]}
                  onChange={this.onChange}
                  label="Allow Swap Bonus -> BTC"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.bonus_eth"
                  isChecked={state["allowExchange.bonus_eth"]}
                  onChange={this.onChange}
                  label="Allow Swap Bonus -> ETH"
                />
              </div>

              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.bonus_ocb"
                  isChecked={state["allowExchange.bonus_ocb"]}
                  onChange={this.onChange}
                  label="Allow Swap Bonus -> OCB"
                />
              </div>
              <div className="w-100">
                <Switch
                  type="switch"
                  name="allowExchange.bonus_trx"
                  isChecked={state["allowExchange.bonus_trx"]}
                  onChange={this.onChange}
                  label="Allow Swap Bonus -> TRX"
                />
              </div>

              <div className="w-100 mb-3">
                <b>Swap Fee (Ex: 0.01 = 1%)</b>
              </div>
              <FormInputs
                ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                properties={[
                  {
                    label: `Profit->BTC`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.profit_btc",
                    value: state["exchangeFee.profit_btc"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.profit_btc"] !== "undefined" &&
                      isFloat(state["exchangeFee.profit_btc"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                  {
                    label: `Profit->ETH`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.profit_eth",
                    value: state["exchangeFee.profit_eth"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.profit_eth"] !== "undefined" &&
                      isFloat(state["exchangeFee.profit_eth"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                  {
                    label: `Profit->OCB`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.profit_ocb",
                    value: state["exchangeFee.profit_ocb"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.profit_ocb"] !== "undefined" &&
                      isFloat(state["exchangeFee.profit_ocb"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                  {
                    label: `Profit->TRX`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.profit_trx",
                    value: state["exchangeFee.profit_trx"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.profit_trx"] !== "undefined" &&
                      isFloat(state["exchangeFee.profit_trx"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                ]}
              />

              <FormInputs
                ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                properties={[
                  {
                    label: `Bonus->BTC`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.bonus_btc",
                    value: state["exchangeFee.bonus_btc"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.bonus_btc"] !== "undefined" &&
                      isFloat(state["exchangeFee.bonus_btc"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                  {
                    label: `Bonus->ETH`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.bonus_eth",
                    value: state["exchangeFee.bonus_eth"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.bonus_eth"] !== "undefined" &&
                      isFloat(state["exchangeFee.bonus_eth"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                  {
                    label: `Bonus->OCB`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.bonus_ocb",
                    value: state["exchangeFee.bonus_ocb"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.bonus_ocb"] !== "undefined" &&
                      isFloat(state["exchangeFee.bonus_ocb"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                  {
                    label: `Bonus->TRX`,
                    type: "text",
                    bsClass: "form-control",
                    name: "exchangeFee.bonus_trx",
                    value: state["exchangeFee.bonus_trx"],
                    onChange: this.onChange,
                    validationState:
                      typeof state["exchangeFee.bonus_trx"] !== "undefined" &&
                      isFloat(state["exchangeFee.bonus_trx"].toString(), {
                        min: 0,
                        max: 1,
                      })
                        ? "success"
                        : "error",
                  },
                ]}
              />

              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                onClick={this.changeSwapSetting}
              >
                Update
              </Button>
              <div className="clearfix" />
            </form>
          </div>

          <div className="col-12 col-md-6 my-3">
            <form className=" px-5 py-3 bg-white rounded">
              <FormInputs
                ncols={["col-12", "col-12", "col-12"]}
                properties={[
                  {
                    label: `Min ETH To Use OCB`,
                    type: "text",
                    bsClass: "form-control",
                    name: "minEthToWithdrawOcb",
                    value: state.minEthToWithdrawOcb,
                    onChange: this.onChange,
                    validationState: "success",
                  },
                  {
                    label: `Latest Android Version`,
                    type: "text",
                    bsClass: "form-control",
                    name: "latestAndroidAppVersion",
                    value: state.latestAndroidAppVersion,
                    onChange: this.onChange,
                    validationState: "success",
                  },
                  {
                    label: `Latest IOS Version`,
                    type: "text",
                    bsClass: "form-control",
                    name: "latestIosAppVersion",
                    value: state.latestIosAppVersion,
                    onChange: this.onChange,
                    validationState: "success",
                  },
                ]}
              />

              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                onClick={() => {
                  this.changeSetting(["minEthToWithdrawOcb","latestAndroidAppVersion","latestIosAppVersion"]);
                }}
              >
                Update
              </Button>
              <div className="clearfix" />
            </form>
          </div>
        
        
          <div className="col-12 col-md-6 my-3">
          
            <form className=" px-5 py-3 bg-white rounded">
              <div className="w-100 mb-3">
                <b>Send State</b>
              </div>
              {Object.keys(this.state.sendState).map(item=>(<div>{item + ': ' +this.state.sendState[item]}</div>))}
              <Button
                bsStyle="info"
                pullRight
                fill
                style={{margin: 16,}}
                type="button"
                onClick={() => {
                  this.getSendState();
                }}
              >
                Get send state
              </Button>
              <Button
                bsStyle="danger"
                pullRight
                style={{margin: 16,}}
                fill
                type="button"
                onClick={() => {
                  this.resetSendState();
                }}
              >
                Reset send state
              </Button>
              <div className="clearfix" />
            </form>
          </div>
        
        </div>
      </div>
    );
  }
}

export default Setting;
